import { StyledTooltip } from 'components/Tooltip'
import React, { useState } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import styled, { css } from 'styled-components'
import { convertHexToRgba } from 'styles/helpers'
import useDarkMode from 'use-dark-mode'

const Label = styled.div`
  font-size: 14px;
  font-weight: ${(props) => props.theme.fontweight.SEMI_BOLD};
  line-height: 1.71429;
  margin-bottom: 14px;
  color: ${(props) =>
      props.$darkMode
          ? props.theme.colors.LIGHT_GRAY
          : props.theme.colors.DARK_GRAY};
`

const StyledDropdown = styled.div`
  position: relative;
  z-index: 3;
  ${(props) =>
      props.$active &&
      css`
      z-index: 10;
    `}
`

const Head = styled.div`
  position: relative;
  height: 48px;
  padding: 0 48px 0 12px;
  border-radius: 12px;
  box-shadow: inset 0 0 0 2px
    ${(props) =>
        props.$darkMode
            ? props.theme.colors.BLUE_GRAY
            : props.theme.colors.LIGHT_GRAY};
  font-size: 14px;
  font-weight: ${(props) => props.theme.fontweight.SEMI_BOLD};
  line-height: 48px;
  color: ${(props) =>
      props.$darkMode
          ? props.theme.colors.SLATE_GRAY
          : props.theme.colors.SATIN_BLACK};
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  user-select: none;
  transition: all 0.2s;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 48px;
    background: none;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M12.707 15.707l-.094.083a1 1 0 0 1-1.32-.083h0l-6-6-.083-.094a1 1 0 0 1 1.497-1.32h0L12 13.585l5.293-5.292.094-.083a1 1 0 0 1 1.32 1.497h0l-6 6z' fill='%236f767e'/%3E%3C/svg%3E")
      no-repeat 50% 50% / 24px auto;
    transition: transform 0.2s;
  }

  &:hover {
    box-shadow: inset 0 0 0 2px
      ${(props) =>
          props.$darkMode
              ? props.theme.colors.DARK_GRAY
              : props.theme.colors.SMOKY_GRAY};
  }

  ${(props) =>
      props.$active &&
      css`
      box-shadow: inset 0 0 0 2px
        ${(props) =>
            props.$darkMode
                ? props.theme.colors.DARK_GRAY
                : props.theme.colors.SMOKY_GRAY};
      background: ${(props) =>
          props.$darkMode
              ? props.theme.colors.CLOSE_BLACK
              : props.theme.colors.WHITE};
      color: ${(props) =>
          props.$darkMode
              ? props.theme.colors.ALMOST_WHITE
              : props.theme.colors.SATIN_BLACK};
      &:before {
        transform: rotate(180deg);
      }
    `}

  ${(props) =>
      props.small &&
      css`
      height: 40px;
      padding: 0 40px 0 16px;
      line-height: 40px;
      &:before {
        width: 40px;
        background-size: 16px auto;
      }
    `}
`

const Selection = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const Body = styled.div`
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  padding: 8px 0;
  border-radius: 12px;
  background: ${(props) =>
      props.$darkMode
          ? props.theme.colors.CLOSE_BLACK
          : props.theme.colors.WHITE};
  box-shadow: inset 0 0 0 2px
      ${(props) =>
          props.$darkMode
              ? props.theme.colors.DARK_GRAY
              : props.theme.colors.LIGHT_GRAY},
    0 4px 12px
      ${(props) =>
          props.$darkMode
              ? convertHexToRgba(props.theme.colors.CLOSE_BLACK, 0.1)
              : convertHexToRgba(props.theme.colors.ALMOST_WHITE, 0.1)};
  visibility: hidden;
  opacity: 0;
  transform: translateY(3px);
  transition: all 0.2s;
  min-width: 100%;
  white-space: nowrap;

  ${(props) =>
      props.bodyUp &&
      css`
      top: auto;
      bottom: calc(100% + 2px);
    `};

  ${(props) =>
      props.$active &&
      css`
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
    `}
`

const Option = styled.div`
  padding: 8px 12px;
  color: ${(props) => props.theme.colors.SLATE_GRAY};
  transition: color 0.2s;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  &:hover {
    color: ${(props) =>
        props.$darkMode
            ? props.theme.colors.ALMOST_WHITE
            : props.theme.colors.SATIN_BLACK};
  }

  ${(props) =>
      props.selected &&
      css`
      color: ${(props) => props.theme.colors.ELECTRIC_BLUE};
    `}

  ${(props) =>
      props.small &&
      css`
      padding: 4px 16px;
    `}
`

const Dropdown = ({
    value,
    setValue,
    options,
    label,
    tooltip,
    small,
    upBody,
    required,
}) => {
    const darkMode = useDarkMode(false)
    const [visible, setVisible] = useState(false)

    const handleClick = (value) => {
        setValue(value)
        setVisible(false)
    }

    return (
        <div>
            <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
                {label && (
                    <Label $darkMode={darkMode.value}>
                        {label}{' '}
                        {required && (
                            <span style={{ color: '#ff6a55' }}>* </span>
                        )}
                        {tooltip && (
                            <StyledTooltip
                                title={tooltip}
                                icon='info'
                                place='top'
                            />
                        )}
                    </Label>
                )}
                <StyledDropdown $active={visible}>
                    <Head
                        $active={visible}
                        small={small}
                        $darkMode={darkMode.value}
                        onClick={() => setVisible(!visible)}
                    >
                        {value && <Selection>{value.label}</Selection>}
                    </Head>
                    <Body
                        bodyUp={upBody}
                        $active={visible}
                        $darkMode={darkMode.value}
                    >
                        {options.map((x, index) => (
                            <Option
                                selected={x.name === value?.name}
                                small={small}
                                $darkMode={darkMode.value}
                                onClick={() => handleClick(x, index)}
                                key={index}
                            >
                                {x.label}
                            </Option>
                        ))}
                    </Body>
                </StyledDropdown>
            </OutsideClickHandler>
        </div>
    )
}

export default Dropdown
