const MB = 1024 * 1024
const GB = 1024 * MB
const MiB = 1024 * 1024
const GiB = 1024 * MiB

/**
 * You need to watch out for multiple things here:
 * - The multipart upload chunk size should be small enough not to kill browser resources
 * - The maximum part limit for S3 is 10,000
 * - Our file upload size has a huge range and can range from 0 to 250GB.
 */

const LOCAL_CONFIG = {
    MULTIPART_UPLOAD_CHUNK_SIZE: 30 * MB,
    MULTIPART_SIZE_MINIMUM: 0.15 * GB,
    FEATURE_FLAGS: {
        COLOR_MANAGEMENT_PRESETS: [
            'google-oauth2|102750304997037641579', // SH
            //'google-oauth2|108023785275756072142', // SH-2
            'google-oauth2|107966522498783434098', // EB
            'auth0|66434083fcacdcaa7fdbbf14', // JA
            'google-oauth2|117043142988903087818', // KB
            'auth0|65d24f1d8e15898d20911ccc',
            'auth0|65d24f1d8e15898d20911ccc', // Testing account
        ],
        ADDONS: [
            'google-oauth2|102750304997037641579', // SH
            'google-oauth2|107966522498783434098', // EB
            'google-oauth2|117043142988903087818', // KB
            'auth0|66434083fcacdcaa7fdbbf14', // JA
        ],
    },
    MULTIPART_SIGN_ON_CLIENT: false,
}

const DEVELOPMENT_CONFIG = {
    MULTIPART_UPLOAD_CHUNK_SIZE: 30 * MB,
    MULTIPART_SIZE_MINIMUM: 4 * GB,
    FEATURE_FLAGS: {
        COLOR_MANAGEMENT_PRESETS: [
            'google-oauth2|102750304997037641579', // SH
            //'google-oauth2|108023785275756072142', // SH-2
            'google-oauth2|107966522498783434098', // EB
            'auth0|66434083fcacdcaa7fdbbf14', // JA
            'google-oauth2|117043142988903087818', // KB
            'auth0|65d24f1d8e15898d20911ccc', // Testing account
        ],
        ADDONS: [
            'google-oauth2|102750304997037641579', // SH
            'google-oauth2|107966522498783434098', // EB
            'google-oauth2|117043142988903087818', // KB
            'auth0|66434083fcacdcaa7fdbbf14', // JA
        ],
    },
}

const PRODUCTION_CONFIG = {
    MULTIPART_UPLOAD_CHUNK_SIZE: 30 * MB,
    MULTIPART_SIZE_MINIMUM: 4 * GB,
    FEATURE_FLAGS: {
        COLOR_MANAGEMENT_PRESETS: [
            'google-oauth2|104267274683232600433', // VUK
            'auth0|664be1972f82e38f6d8b5ff2', // cheuq
            'google-oauth2|102750304997037641579', // SH
            'google-oauth2|107966522498783434098', // EB
            'google-oauth2|117043142988903087818', // KB
            'auth0|66434083fcacdcaa7fdbbf14', // JA
            'google-oauth2|112762355857327987663', // KM (Motto)
            'auth0|65d24f1d8e15898d20911ccc', // Testing account
        ],
        ADDONS: [
            'google-oauth2|102750304997037641579', // SH
            'google-oauth2|107966522498783434098', // EB
            'google-oauth2|117043142988903087818', // KB
            'auth0|66434083fcacdcaa7fdbbf14', // JA
            'google-oauth2|112762355857327987663', // KM (Motto)
        ],
    },
}

const CONFIG = (() => {
    const isLocal = window.location.hostname === 'localhost'
    const isDevelopment = window.location.hostname.includes(
        'development.app.renderjuice.com',
    )
    const isProduction = window.location.hostname.includes(
        'app.renderjuice.com',
    )
    console.log(
        `ENVIRONMENT: ${
            isLocal ? 'LOCAL' : isDevelopment ? 'DEVELOPMENT' : 'PRODUCTION'
        }`,
    )
    if (isLocal) return LOCAL_CONFIG
    if (isProduction) return PRODUCTION_CONFIG
    return DEVELOPMENT_CONFIG
    // throw new Error('No config found for this environment')
})()

export default CONFIG
