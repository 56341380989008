import Icon from 'components/Icon'
import React from 'react'
import styled, { css } from 'styled-components'
import useDarkMode from 'use-dark-mode'

const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
`

const TooltipContent = styled.div`
  position: absolute;

  background: ${(props) =>
      props.$darkMode
          ? props.theme.colors.DARK_GRAY
          : props.theme.colors.SATIN_BLACK};
  color: ${(props) => props.theme.colors.ALMOST_WHITE};
  width: max-content;
  max-width: 400px;
  // display: none;
  visibility: hidden;
  opacity: 0;
  padding: 3px 12px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: ${(props) => props.theme.fontweight.SEMI_BOLD};
  z-index: 99;
  text-align: start;

  ${(props) =>
      props.$place === 'bottom'
          ? css`
          bottom: calc(-100% - 5px);
          left: 50%;
          transform: translateX(-50%);
        `
          : props.$place === 'right'
              ? css`
          bottom: 0;
          left: calc(100% + 5px);
        `
              : props.$place === 'left'
                  ? css`
          bottom: 0;
          right: calc(100% + 5px);
        `
                  : css`
          bottom: calc(100% + 5px);
          left: 50%;
          transform: translateX(-50%);
        `}
`

const TooltipTrigger = styled.span`
  display: inline-block;
  cursor: pointer;
  &:hover {
    & > div {
      visibility: visible;
      opacity: 1;
    }
  
  }
`

const StyledIcon = styled(Icon)`
  cursor: pointer;
  &:hover {
    + div {
      // display: block;
      visibility: visible;
      opacity: 1;
    }
  }
`

const StyledElement = styled.div`
  cursor: pointer;
  }
`

const Tooltip = ({ className, title, icon, iconSize, place, children }) => {
    const darkMode = useDarkMode(false)
    return (
        <TooltipContainer className={className}>
            <TooltipTrigger>
                {children ? (
                    <StyledElement>{children}</StyledElement>
                ) : (
                    <StyledIcon name={icon} size={iconSize} />
                )}
                <TooltipContent $darkMode={darkMode.value} $place={place}>
                    {title}
                </TooltipContent>
            </TooltipTrigger>
        </TooltipContainer>
    )
}

export const StyledTooltip = styled(Tooltip)`
  position: relative;
  top: -1px;
  svg {
    fill: ${(props) => props.theme.colors.SMOKY_GRAY};
  }
`

export default Tooltip
